const VueAxiosPlugin = {}

export default VueAxiosPlugin.install = function(Vue, { axios }) {
  Vue.axios = axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios
      },
    },
  })
}
