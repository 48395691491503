$(document).ready(function(){
  // 問い合わせ種別の変更時に問い合わせ詳細の内容を変更する
  $('.inquiry_type').change(function() {
    const textarea = $('#inquiry_content')
    const inquiryType = $(this).val()
    const schedulingMethod = $(this).data('schedulingMethod')
    const defaultDetail = $(this).data('defaultDetail')

    textarea.val(defaultDetail)
    if (inquiryType !== 'requesting_viewing') {
      textarea.focus()
    }
    M.textareaAutoResize(textarea)

    const reservationCalendar = $('.reservation-calendar')
    const previewCheck = $('.preview-check')
    const viewingRadio = $('.viewing-radio')
    const previewRadio = $('.preview-radio')

    if (inquiryType === 'requesting_viewing') {
      reservationCalendar.show()
      previewCheck.show()
      viewingRadio.prop('required', true)
      previewRadio.prop('required', true)
    } else {
      reservationCalendar.hide()
      previewCheck.hide()
      viewingRadio.prop('required', false)
      previewRadio.prop('required', false)
    }

    const supportText = $(this).data('supportText') || ''
    const div = $('#support_text')
    div.text(supportText)

    const contactSettingFile = $(this).data('fileName') || ''
    const contactSettingFileDownloadLink = $(this).data('fileDownloadLink') || ''
    const contact_setting_file = $('#contact_setting_file')
    contact_setting_file.text(contactSettingFile)
    contact_setting_file.attr("href", contactSettingFileDownloadLink)
  })

  $('.inquiry-documents-field .inquiry-documents')
    .blur(function() {
      const $el = $('.inquiry-documents-field .inquiry-cache-documents')
      if ($el.length) {
        $el.remove()
        $('.inquiry-documents-field .file-path').val('')
      }
    })
    .on('direct-upload:error', function(e) {
      e.preventDefault()

      M.toast({
        html: 'ファイルのアップロードに失敗しました。ページを更新してください。',
        displayLength: 'stay',
      })
    })
})
