const IsSmartphonePlugin = {}

export default IsSmartphonePlugin.install = function(Vue) {
  Vue.mixin({
    computed: {
      isSmartphone() {
        return document.getElementsByTagName('body')[0].classList.contains('is-smartphone')
      },
    },
  })
}
