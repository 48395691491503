import * as axiosBase from 'axios'
import qs from 'qs'
import { alertError } from '@/utilities/alert'

const axios = axiosBase.create({
  paramsSerializer: params => qs.stringify(params, { indicesfalse: false, arrayFormat: 'brackets' }),
})

axios.interceptors.request.use(
  config => {
    const metaTag = document.querySelector('meta[name="csrf-token"]')
    const csrfToken = metaTag ? metaTag.getAttribute('content') : ''

    config.headers = {
      "Content-Type": 'application/json',
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": csrfToken,
    }
    return config
  },
  error => Promise.reject(error),
)

axios.interceptors.response.use(
  // サーバーからエラーメッセージが送られている場合、それを表示する
  // responseをdataでラップしているのを外す
  ({ data }) => {
    const error = data.error || data.errors
    if (error) {
      alertError(error)
      return {}
    }
    return data
  },
  // サーバーで予期せぬエラーが発生した場合、デフォルトのメッセージを表示する
  error => {
    alertError()
    return {}
  },
)

export { axios }
