<template>
  <tbk-modal class="tbk-confirm-modal" :class="{ 'tbk-confirm-modal--sp' : isSmartphone }" @close="close">
    <template #header>
      <h5 class="tbk-confirm-modal__title" v-text="title" />
    </template>
    <template #body>
      <div v-html="message" /><!-- eslint-disable-line -->
    </template>
    <template #footer>
      <a
        v-for="(action, index) in actions"
        :key="index"
        class="btn-flat waves-effect waves-green ml-1"
        :class="actionClass(action)"
        @click.stop="onClickAction(action)"
        v-text="action.text"
      />
    </template>
  </tbk-modal>
</template>

<script>
import TbkModal from '~/TbkModal'

export default {
  components: {
    TbkModal,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    actionClass(action) {
      return {
        'teal-text': action.type === 'ok',
        'red-text': action.type === 'danger',
        'grey-text': action.type === 'abort',
      }
    },
    onClickAction(action) {
      if (action.callback) {
        action.callback()
      }
      this.close()
    },
    close() {
      document.body.removeChild(this.$el)
      this.$destroy()
    },
  },
}
</script>

<style lang="scss" scoped>
.tbk-confirm-modal {
  &__title {
    text-align: left;
    font-weight: bold;
  }
}

.tbk-confirm-modal::v-deep {
  .tbk-modal__container {
    padding-bottom: 6px;
  }

  .tbk-modal__footer {
    text-align: right;
    font-weight: bold;
  }
}

.tbk-modal.tbk-confirm-modal--sp::v-deep {
  z-index: 10001;

  .tbk-modal__container {
    width: 95%;
  }
}
</style>
