import Vue from 'vue'
import TbkModalPlugin from './plugins/tbk-modal'
import VueAxiosPlugin from './plugins/vue-axios'
import IsSmartphonePlugin from './plugins/is-smartphone'
import { axios } from './axios-client'

global.vues = []

Vue.use(TbkModalPlugin)
Vue.use(VueAxiosPlugin, { axios })
Vue.use(IsSmartphonePlugin)

const render = async(el) => {
  const { default: component } = await import(`~/${el.dataset.vue}.vue`)
  el.removeAttribute('data-vue')

  const app = new Vue({
    render: h => h(component, { props: el.dataset }),
  }).$mount(el)
  global.vues.push(app)
}

document.addEventListener('DOMContentLoaded', () => {
  (async() => {
    const elements = document.querySelectorAll('[data-vue]')
    await Promise.all(Array.from(elements, render))
  })()
})
