export function alertError(error) {
  let messages = []
  if (error instanceof Error) {
    messages.push(error.message)
  } else if (Array.isArray(error)) {
    messages = error
  } else if (typeof error === 'string') {
    messages.push(error)
  }

  const html = messages.filter(message => message).join('<br>')
    || 'サーバーでエラーが発生しました。管理者にお問い合わせください'

  M.toast({ html, displayLength: 'stay' })
}
