<template>
  <tbk-modal class="tbk-email-modal" :class="{ 'tbk-email-modal--sp': isSmartphone }">
    <template #header>
      <div class="tbk-email-modal__send" @click="send">
        <i class="material-icons waves-effect">
          send
        </i>送信
      </div>
      <div class="tbk-email-modal__add-cc waves-effect" :class="{ 'is-disabled': !canAddCc }" @click="addCc">
        Cc
      </div>
      <div class="tbk-email-modal__add-bcc waves-effect" :class="{ 'is-disabled': !canAddBcc }" @click="addBcc">
        Bcc
      </div>
      <div class="material-icons tbk-email-modal__close waves-effect" @click="close">
        close
      </div>
    </template>

    <template #body>
      <form ref="form" class="tbk-email-modal__form">
        <div class="tbk-email-modal__from">
          <div class="tbk-email-modal__from-label tooltipped" :data-tooltip="replyToTooltip">
            From: {{ mailFrom }}
          </div>
        </div>
        <div class="tbk-email-modal__reply-to">
          <div class="tbk-email-modal__reply-to-label">
            ReplyTo
          </div>
          <input v-model="formValues.reply_to" type="email" required />
        </div>
        <div class="tbk-email-modal__to">
          <div class="tbk-email-modal__to-label">
            To
          </div>
          <input v-model="formValues.to" type="email" required />
        </div>
        <div v-for="(_cc, index) in formValues.cc" :key="`cc-${index}`" class="tbk-email-modal__cc">
          <div class="tbk-email-modal__cc-label">
            Cc
          </div>
          <input v-model="formValues.cc[index]" type="email" required />
          <i class="material-icons" @click="removeCc(index)">
            close
          </i>
        </div>
        <div v-for="(_bcc, index) in formValues.bcc" :key="`bcc-${index}`" class="tbk-email-modal__bcc">
          <div class="tbk-email-modal__bcc-label">
            Bcc
          </div>
          <input v-model="formValues.bcc[index]" type="email" required />
          <i class="material-icons" @click="removeBcc(index)">
            close
          </i>
        </div>
        <div class="tbk-email-modal__subject">
          <input
            ref="subject"
            v-model="formValues.subject"
            placeholder="件名"
            data-length="255"
          />
        </div>
        <div class="tbk-email-modal__body">
          <textarea
            ref="body"
            v-model="formValues.body"
            class="materialize-textarea"
            placeholder="本文"
            data-length="65535"
          />
        </div>
      </form>
    </template>
  </tbk-modal>
</template>

<script>
import TbkModal from '~/TbkModal'
import { DirectUpload } from '@rails/activestorage'
import { alertError } from '@/utilities/alert'

const MAX_ATTACHMENTS = 5
const MAX_CC = 5
const MAX_BCC = 5

export default {
  components: {
    TbkModal,
  },

  props: {
    mailType: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      default: null,
    },
    to: {
      type: String,
      default: '',
    },
    replyTo: {
      type: String,
      default: '',
    },
    cc: {
      type: [String, Array],
      default: () => [],
    },
    bcc: {
      type: [String, Array],
      default: () => [],
    },
    subject: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    attachments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      formValues: {
        to: this.to,
        reply_to: this.replyTo,
        cc: this.parseMailto(this.cc),
        bcc: this.parseMailto(this.bcc),
        subject: this.subject,
        body: this.body,
        attachments: this.attachments,
      },
      isSending: false,
    }
  },

  computed: {
    mailFrom() {
      return process.env.MAIL_FROM
    },
    replyToTooltip() {
      return `メールは \`${this.mailFrom}\` より送信されます。お客様がこのメールに返信するときReplyToのアドレスが使用されます`
    },
    canAddCc() {
      return this.formValues.cc.length < MAX_CC
    },
    canAddBcc() {
      return this.formValues.bcc.length < MAX_BCC
    },
    canAttach() {
      return this.formValues.attachments.length < MAX_ATTACHMENTS
    },
  },

  mounted() {
    $('.tbk-email-modal .tooltipped').tooltip({ enterDelay: 1000 })

    $('input[data-length], textarea[data-length]').characterCounter()
    $('.tbk-email-modal__body .character-counter').appendTo('.tbk-modal__container')
    this.toggleCharacterCounter(this.$refs.subject, '.tbk-email-modal__subject')
    this.toggleCharacterCounter(this.$refs.body, '.tbk-modal__container')

    M.textareaAutoResize(this.$refs.body)
    $(this.$refs.body).on('input', this.setMinHeightToBody)
    this.setMinHeightToBody()
  },

  methods: {
    toggleCharacterCounter(el, scope) {
      $(el)
        .on('focus', () => $(`${scope} > .character-counter`).show())
        .on('blur', () => $(`${scope} > .character-counter`).hide())
    },
    setMinHeightToBody() {
      $(this.$refs.body).css('min-height', `${this.$refs.body.scrollHeight}px`)
    },
    onChangeFile() {
      const files = Array.from(this.$refs.fileInput.files)
      if (files.length + this.formValues.attachments.length <= MAX_ATTACHMENTS) {
        files.forEach(file => this.uploadFile(file))
      } else {
        alertError(`ファイルは${MAX_ATTACHMENTS}個まで添付可能です。`)
      }
      this.$refs.fileInput.value = null
    },
    parseMailto(obj) {
      if (Array.isArray(obj)) {
        return obj
      } else if (typeof obj === 'string') {
        return obj.split(',')
      } else {
        return []
      }
    },
    close() {
      document.body.removeChild(this.$el)
      this.$destroy()
    },
    addCc() {
      if (this.canAddCc) {
        this.formValues.cc.push('')
      }
    },
    addBcc() {
      if (this.canAddBcc) {
        this.formValues.bcc.push('')
      }
    },
    removeCc(index) {
      this.formValues.cc.splice(index, 1)
    },
    removeBcc(index) {
      this.formValues.bcc.splice(index, 1)
    },
    uploadFile(file) {
      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
      upload.create((error, blob) => {
        if (error) {
          alertError('ファイルのアップロードに失敗しました。時間をおいて再度お試しください。')
        } else {
          this.formValues.attachments.push(blob)
        }
      })
    },
    removeAttachment(index) {
      this.formValues.attachments.splice(index, 1)
    },
    async send() {
      if (this.isSending) {
        return
      }
      if (!this.$refs.form.checkValidity()) {
        return this.$refs.form.reportValidity()
      }

      this.isSending = true

      const email = { ...this.formValues }
      email.mail_type = this.mailType
      email.cc = email.cc.join(',')
      email.bcc = email.bcc.join(',')
      email.attachments = email.attachments.map(blob => blob.signed_id)

      const res = await this.axios.post(this.path, { email })
      if (res.status === 'created' && this.callback) {
        this.callback(res)
        this.close()
      }
      this.isSending = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../stylesheets/_color_valiables";

.tbk-email-modal {
  input {
    border: none !important;
    box-shadow: none !important;
    height: 48px !important;
    min-height: 48px !important;
    margin: 0;
  }

  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  %control {
    height: 48px;
    line-height: 48px;
    width: 48px;
    text-align: center;
    color: map-get($grey, "darken-1");
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: map-get($grey, "lighten-4");
    }
  }

  &__send {
    @extend %control;

    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 28px;

    i {
      overflow: visible;
    }
  }

  &__add-cc {
    @extend %control;

    &.is-disabled {
      color: map-get($grey, "lighten-4");
    }
  }

  &__add-bcc {
    @extend %control;

    &.is-disabled {
      color: map-get($grey, "lighten-4");
    }
  }

  &__close {
    position: absolute;
    font-size: 28px;
    top: 0;
    right: 0;
    height: 48px;
    width: 48px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
  }

  %row {
    display: flex;
    position: relative;
    height: 48px;
    min-height: 48px;
    line-height: 48px;
    border-bottom: solid 1px map-get($grey, "lighten-3");
  }

  %label {
    display: inline-block;
    padding: 0 8px;
    color: map-get($grey, "darken-1");
  }

  %remove-icon {
    @extend %control;

    position: absolute;
    right: 0;
    font-size: 16px;
  }

  &__from {
    &-label {
      @extend %label;

      font-size: 12px;
      margin-bottom: -8px;
    }
  }

  &__reply-to {
    @extend %row;

    &-label {
      @extend %label;
    }

    input {
      padding-right: 8px !important;
    }
  }

  &__to {
    @extend %row;

    &-label {
      @extend %label;
    }

    input {
      padding-right: 8px !important;
    }
  }

  &__cc {
    @extend %row;

    &-label {
      @extend %label;
    }

    input {
      padding-right: 48px !important;
    }

    i {
      @extend %remove-icon;
    }
  }

  &__bcc {
    @extend %row;

    &-label {
      @extend %label;
    }

    input {
      padding-right: 48px !important;
    }

    i {
      @extend %remove-icon;
    }
  }

  &__subject {
    @extend %row;

    input {
      padding: 0 8px;
      width: 100%;
      box-sizing: border-box;

      &::placeholder {
        color: map-get($grey, "darken-1");
      }
    }

    &::v-deep {
      .character-counter {
        position: absolute;
        right: 0;
        bottom: 20px;
        height: 8px;
        min-height: 8px;
      }
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    textarea {
      flex-grow: 1;
      padding: 16px 8px 0 8px;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      resize: none;
      font-size: 16px;
      margin: 0;

      &::placeholder {
        color: map-get($grey, "darken-1");
      }
    }
  }
}

.tbk-email-modal::v-deep {
  .tbk-modal {
    &__container {
      height: 85%;
      max-height: 85%;
      width: 70%;
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: 0 8px;
      position: fixed;

      > .character-counter {
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: right;
        background-color: #fff;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      height: 48px;
      min-height: 48px;
      margin: 0 8px;
    }

    &__body {
      height: calc(100% - 48px);
      overflow-y: auto;
      margin-bottom: 16px;
    }
  }
}

.tbk-email-modal.tbk-email-modal--sp::v-deep {
  z-index: 10001;

  .tbk-modal__container {
    top: 0;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
    padding: 0;
    margin: 0;
  }
}
</style>
