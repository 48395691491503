require("@rails/ujs").start()
require("@rails/activestorage").start()

require("jquery")

import "materialize-css/dist/js/materialize"

import "../../stylesheets/agents/application.scss"
import "./properties"
import "./inquiries"

import "../vue-renderer"
