<template>
  <transition name="tbk-modal">
    <div class="tbk-modal" @click.self.stop="$emit('close')">
      <div class="tbk-modal__container" :class="{ 'scroll': scroll }" @click.stop>
        <div v-if="$slots.header" class="tbk-modal__header">
          <slot name="header" />
        </div>

        <div class="tbk-modal__body">
          <slot />
          <slot name="body" />
        </div>

        <div v-if="$slots.footer" class="tbk-modal__footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    scroll: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.tbk-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  &__container {
    position: fixed;
    left: 0;
    right: 0;
    top: 10%;
    margin: 0 auto;
    width: 55%;
    max-height: 70%;
    padding: 24px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    font-family: Helvetica, Arial, sans-serif;

    &.scroll {
      display: flex;
      flex-direction: column;

      .tbk-modal__body {
        flex-grow: 1;
        overflow: auto;
      }
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 20px;
  }

  &__footer {
    margin-top: 20px;
  }

  /* The following styles are auto-applied to elements with transition="tbk-modal" */
  &.tbk-modal-enter-active,
  &.tbk-modal-leave-active {
    transition: opacity 0.3s ease;

    .tbk-modal__container {
      transition: all 0.3s ease;
    }
  }

  &.tbk-modal-enter,
  &.tbk-modal-leave-to {
    opacity: 0;

    .tbk-modal__container {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      top: 4%;
    }
  }
}
</style>
