import TbkConfirmModal from '~/TbkConfirmModal'
import TbkEmailModal from '~/TbkEmailModal'

const TbkModalPlugin = {}

export default TbkModalPlugin.install = function(Vue) {
  const mount = component => props => {
    const el = document.createElement('div')
    document.body.appendChild(el)
    const app = new Vue({
      render: h => h(component, {
        props,
        destroyed() {
          const index = global.vues.findIndex(vue => vue === app)
          global.vues.splice(index, 1)
        },
      }),
    }).$mount(el)
    global.vues.push(app)
  }
  const $modal = {
    confirm: mount(TbkConfirmModal),
    email: mount(TbkEmailModal),
  }

  Vue.$modal = $modal
  Vue.prototype.$modal = $modal
}
